import URL from 'url-parse';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {createConvoElement, sendMetric, startSSO, startTrackingEvents} from '@mol-fe/mol-fe-openweb-core';
import {getArticleScript} from './articleComment';
import {initLiveBlogOW} from './liveBlogComment';
// eslint-disable-next-line import/no-unassigned-import
import './styles.scss';

const MODULE_PAGE = 'page';
const MODULE_ARTICLE = 'article';

const attemptLogin = () => {
  const articleUrl = new URL(window.location.href, true);

  articleUrl.set('hash', '');
  articleUrl.set('query', {
    ...articleUrl.query || {},
    openWebLoggedIn: true
  });

  const {renderPlatform} = getPageMetadata();
  const regSource = renderPlatform === 'mobile' ? 'mweb_openweb' : 'openweb';
  const redirectUrl = `/registration/login.html?reg_source=${regSource}&targetUrl=${encodeURIComponent(articleUrl.href)}`;

  window.location.href = redirectUrl;
};

let mainContainer = null;
let timeCommentsVisible = null;

const prepareMainContainer = () => {
  const {renderPlatform} = getPageMetadata();

  if (renderPlatform === 'desktop') {
    mainContainer = document.querySelector('#reader-comments') || document.querySelector('#reader-comments-in-overlay');
    mainContainer.innerHTML = '';

    const handleIntersect = (entries, currObserver) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          timeCommentsVisible = Date.now();
          currObserver.disconnect();
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect);

    observer.observe(mainContainer);
  } else if (renderPlatform === 'mobile') {
    mainContainer = document.querySelector('#comments');
    mainContainer.innerHTML = '';
  }
};

const openMobileComments = () => {
  document.body.classList.add('comments-on');
  mainContainer.classList.add('comments-active');
  window.scrollTo(0, 0);

  if (!timeCommentsVisible) {
    timeCommentsVisible = Date.now();
  }
};

const closeMobileComments = () => {
  document.body.classList.remove('comments-on');
  mainContainer.classList.remove('comments-active');
  location.hash = '';
};

const getStartWithCommentsOpen = () =>
  window.location.hash && window.location.hash.startsWith('#comments') ||
  window.location.search && window.location.search.match(/openWebLoggedIn/) ||
  window.location.search && window.location.search.match(/spot_im_scroll_to_comments=true/) ||
  window.location.search && window.location.search.match(/spot_im_highlight_immediate=true/);

const afterLoad = () => {
  const {renderPlatform} = getPageMetadata();
  const {openwebMessagesCount} = getPageCriteria();

  const commentsEl = createConvoElement({
    messagesCount: openwebMessagesCount,
    module: MODULE_ARTICLE
  });
  const startWithCommentsOpen = getStartWithCommentsOpen();

  document.documentElement.classList.add('has-openweb-comments');

  // add the conversation module on entering viewport for desktop, on open for mobile
  // openweb script loads too slow to lazy load effectively

  if (renderPlatform === 'desktop') {
    const anchor = document.createElement('a');
    const anchorNew = document.createElement('a');

    anchor.id = 'comments';
    anchorNew.id = 'newcomment';
    mainContainer.appendChild(anchor);
    mainContainer.appendChild(anchorNew);
    mainContainer.appendChild(commentsEl);

    if (startWithCommentsOpen) {
      commentsEl.scrollIntoView();
      document.addEventListener('spot-im-conversation-loaded', () => {
        commentsEl.scrollIntoView();
      });
    }
  } else if (renderPlatform === 'mobile') {
    mainContainer.appendChild(commentsEl);

    const closeButton = document.createElement('button');

    closeButton.addEventListener('click', closeMobileComments);
    closeButton.classList.add('closeCommentsButton');
    mainContainer.appendChild(closeButton);

    const openButtons = Array.from(document.querySelectorAll('.comments-count, [data-open-comments]'));

    for (const openButton of openButtons) {
      openButton.addEventListener('click', openMobileComments);
    }

    document.documentElement.classList.remove('waiting-on-comments');

    document.addEventListener('floating_unit_clicked', () => {
      openMobileComments();
    });

    if (startWithCommentsOpen) {
      openMobileComments();
    }
  }

  later.go('OPENWEB_LOADED');
};

const openCustomProfile = (event) => {
  try {
    const {userId} = event.detail;
    const isCurrentUser = window.DM.userId && window.DM.userId === userId;
    const url = isCurrentUser ? '/registration/profile.html' : `/registration/${userId}/profile.html`;

    sendMetric({
      type: 'profile',
      userId
    });
    location.href = url;
  } catch (error) {
    logger.error('Error opening profile', error);
  }
};

const initiateSSO = async () => {
  try {
    const response = await startSSO();

    if (response === 'guest' || response === 'success') {
      document.documentElement.classList.add('mol-fe-openweb-loaded');
    }

    logger.info(`initiateSSO: status ${response}`);
  } catch (error) {
    logger.warn('initiateSSO: OW error', error);
  }
};

export const init = () => {
  const pageCriteria = getPageCriteria();
  const {pageType, readerComments} = pageCriteria;

  if (pageType !== 'article' || readerComments === 'X') {
    return;
  }

  prepareMainContainer();

  if (pageCriteria.liveBlog) {
    initLiveBlogOW();
  }

  const {articleId} = getPageMetadata();
  const script = getArticleScript(articleId);

  script.onload = afterLoad;
  script.onerror = () => sendMetric({
    moduleType: MODULE_PAGE,
    type: 'error'
  });
  document.head.appendChild(script);

  document.addEventListener('spot-im-api-ready', initiateSSO, false);
  document.addEventListener('spot-im-login-start', attemptLogin);
  document.addEventListener('ow-open-custom-user-profile', openCustomProfile);
  startTrackingEvents();

  const intervalCheckLoaded = setInterval(() => {
    const openwebContainer = document.querySelector('[data-spotim-module="conversation"]');
    const shadowContainer = openwebContainer && openwebContainer.children && openwebContainer.children[0];
    const shadowRoot = shadowContainer && shadowContainer.shadowRoot;

    if (!shadowRoot) {
      return;
    }

    const innerElement = shadowRoot.querySelector('.spcv_sort-container,.spcv_editor-wrapper');

    if (innerElement) {
      clearInterval(intervalCheckLoaded);

      sendMetric({
        moduleType: MODULE_PAGE,
        type: 'load',
        value: timeCommentsVisible ? Date.now() - timeCommentsVisible : 0
      });
    }
  }, 200);
};

try {
  const {loadOpenwebLauncherAfterScroll, openwebEnabled} = getPageCriteria();
  const loadAfterScroll = loadOpenwebLauncherAfterScroll || window.location.search && window.location.search.match(/debugLoadOpenwebLauncherAfterScroll=true/);
  const isEnabled = window.location.search && window.location.search.match(/debugOpenWeb=true/) || openwebEnabled;
  const startWithCommentsOpen = getStartWithCommentsOpen();

  if (isEnabled) {
    if (loadAfterScroll && !startWithCommentsOpen) {
      let initialised = false;
      const {renderPlatform} = getPageMetadata();
      const scrollHandler = () => {
        if (!initialised) {
          initialised = true;
          init();
        }
        document.removeEventListener('scroll', scrollHandler);
      };

      document.addEventListener('scroll', scrollHandler);

      if (renderPlatform === 'mobile') {
        const openButton = document.querySelector('.comments-count');
        const clickHandler = () => {
          if (!initialised) {
            initialised = true;
            init();
          }
          openMobileComments();
          document.removeEventListener('scroll', scrollHandler);
          openButton.removeEventListener('click', clickHandler);
        };

        document.documentElement.classList.remove('waiting-on-comments');
        openButton.addEventListener('click', clickHandler);
      }
    } else {
      init();
    }
  }
} catch (error) {
  logger.error('Error initialising Openweb', error);
  sendMetric({
    moduleType: MODULE_PAGE,
    type: 'error'
  });
}
